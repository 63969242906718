/* eslint-disable max-len */
import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { Input } from 'antd';
import Image from 'next/image';
import Cookies from 'js-cookie';
import Provider from './provider';
import Gameh5 from './gameh5';
import { SettingActions } from '../reducers/settingReducer';
import { getGameType, getGameName } from '../constant/getGameType';
import Banner from './Banner';

import {
  ChevronDown,
} from '../../images';
import BackToTopButton from '../common/BackToTop';

const { Search } = Input;

function App({ data }) {
  const router = useRouter();
  const dispatch = useDispatch();
  const elementRef = useRef(null);
  const elementWindow = typeof window !== 'undefined'
    && (document.getElementById('page-container') || null);
  const { t } = useTranslation('common');
  const { name, category } = router.query;
  const [gameBrands, setGameBrands] = useState([]);
  const [gameList, setGameList] = useState([]);
  const [initialGameList, setInitialGameList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [limit, setLimit] = useState(21);
  const [selectedValue, setSelectedValue] = useState('');
  const [userId, setUserId] = useState(Cookies.get('userId'));

  const type = getGameType(category);
  const fetchGames = async (brands, id) => {
    setGameList([]);
    setInitialGameList([]);
    // setLoading(true);

    const filterBrand = brands.find((x) => x._id === id);
    const filterType = filterBrand?.games
      ?.filter((x) => x.type === type.toString())
      .filter((x) => x.status === '0');
    // .filter((x) => x.thumbnail);
    if (filterType) {
      filterType.map((x) => (x.brandThumbnail = filterBrand.thumbnail));
    }
    setGameList(filterType);
    setInitialGameList(filterType);
    // setLoading(false);
  };

  useEffect(() => {
    const handleRouteChange = (url) => {
      // Do something with the new URL
      setSelectedVendor('');
    };

    // Listen to the router's routeChangeComplete event
    router.events.on('routeChangeComplete', handleRouteChange);

    // Clean up the event listener when the component is unmounted
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []); // Empty dependenc

  useEffect(() => {
    const selectedProvider = selectedVendor || name;
    if (selectedProvider) {
      const i = gameBrands.find((x) => x.name === selectedProvider);
      if (i) {
        setSelectedVendor(i._id);
      }
    }
    dispatch(SettingActions.setLoading(false));
  }, [gameBrands, category, name]);

  useEffect(() => {
    if (type === 'recommended' || type === 'hotGames') {
      const result = {
        recommendedGames: [],
        hotGames: [],
      };
      data.gameIcons.map((x) => {
        x.games.map((x1) => {
          data.hotGames.map((x2) => {
            if (x1._id === x2.gameId) {
              let value = {};
              value = x1;
              value.brandId = x._id;
              value.thumbnail = x2.url;
              result.hotGames.push(value);
            }
          });
        });
      });
      if (type === 'recommended') {
        setGameList(result.recommendedGames);
        setInitialGameList(result.recommendedGames);
      } else if (type === 'hotGames') {
        setGameList(result.hotGames);
        setInitialGameList(result.hotGames);
      }
    } else {
      let result = [];

      // Replace icon from brand icons
      // Filter visible brand
      data.brandIcons = data.brandIcons.filter((x) => x.isVisible);

      data.brandIcons.map((x) => {
        if (x[`imageUrl_${type}`]) {
          result.push({
            _id: x.brandId,
            games: x.games, // all games show
            isVisible: x.isVisible,
            name: x.name,
            sorting: x.sorting,
            sortings: x.sortings?.map((x) => (x === 0 ? null : x)),
            thumbnail: x[`imageUrl_${type}`],
          });
        }
      });

      if (type === 12 || type === '12') {
        result = _.sortBy(result, ['sortings[0]']);
      } else if (type === 8 || type === '8') {
        result = _.sortBy(result, ['sortings[1]']);
      } else if (type === 0 || type === '0') {
        result = _.sortBy(result, ['sortings[2]']);
      } else if (type === 4 || type === '4') {
        result = _.sortBy(result, ['sortings[3]']);
      } else if (type === 1 || type === '1' || type === 7 || type === '7') {
        result = _.sortBy(result, ['sortings[4]']);
      } else if (type === 3 || type === '3') {
        result = _.sortBy(result, ['sortings[6]']);
      }
      result = result.filter((i) => i.isVisible);
      setGameBrands(result);

      if (selectedVendor) {
        fetchGames(result, selectedVendor);
      } else {
        const gamesResult = [];

        result.map((brand) => {
          brand.games.map((x) => {
            if (x.status !== '1') {
              gamesResult.push({
                ...x,
                brandThumbnail: brand.thumbnail,
                brandName: brand.name,
                brandId: brand._id.toString(),
              });
            }
          });
        });

        let allgames = [];
        if (category.includes('instant')) {
          allgames = gamesResult.filter((game) => (game.type == '1' || game.type == '7')
            && game?.thumbnail
            && game?.vendorGameId
            && game.status !== '1');
        } else {
          allgames = gamesResult.filter(
            (x) => x.type === type.toString()
              && x?.thumbnail
              && x?.vendorGameId
              && x.status !== '1',
          );
        }

        allgames = allgames.sort((a, b) => {
          // Check if both games have the recommended property
          if (a.recommended !== undefined && b.recommended !== undefined) {
            return (
              b.recommended - a.recommended || a.name.localeCompare(b.name)
            );
          }

          // If one of the games doesn't have the recommended property, prioritize the one that has it
          if (a.recommended !== undefined) {
            return -1;
          }
          if (b.recommended !== undefined) {
            return 1;
          }

          // If neither game has the recommended property, just sort by name
          return a.name.localeCompare(b.name);
        });

        allgames = allgames.sort((a, b) => {
          if (a.name === 'Aviator') return -1; // a should come first
          if (b.name === 'Aviator') return 1; // b should come first
          return 0; // leave other items unchanged
        });

        setGameList(allgames);
        setInitialGameList(allgames);
        setLoading(false);
      }
    }
  }, [router.asPath, type, selectedVendor]);

  function rearrangeArray(arrayA, arrayB) {
    const lookup = new Set(arrayA.map((obj) => obj._id));

    arrayB.sort((obj1, obj2) => {
      if (lookup.has(obj1._id) && !lookup.has(obj2._id)) {
        return -1;
      } if (!lookup.has(obj1._id) && lookup.has(obj2._id)) {
        return 1;
      }
      return 0;
    });

    return arrayB;
  }

  const handleSearch = (search, filter) => {
    let result = _.filter(initialGameList, (i) => i.name.toLowerCase().includes(search.toLowerCase()));

    if (filter === 'popularity') {
      result = _.filter(initialGameList, (i) => i.name.toLowerCase().includes(search.toLowerCase())).sort((a, b) => (a.recommended || 0) - (b.recommended || 0));
    }
    if (filter === 'a-z') {
      result = _.filter(initialGameList, (i) => i.name.toLowerCase().includes(search.toLowerCase())).sort((a, b) => a.name.localeCompare(b.name));
    }
    if (filter === 'z-a') {
      result = _.filter(initialGameList, (i) => i.name.toLowerCase().includes(search.toLowerCase())).sort((a, b) => b.name.localeCompare(a.name));
    }
    if (filter === 'recent') {
      const getRelatedType = (JSON.parse(localStorage.getItem(`recentlyPlayedGames${userId}`)) || []);
      const specificIds = getRelatedType.filter((x) => x.type === type?.toString()).map((obj) => obj._id);

      function sortBySpecificIdsFirst(array, specificIds) {
        // Create a set of specific IDs for faster lookup
        const specificIdsSet = new Set(specificIds);

        // Partition the array into two parts: objects with specific IDs and others
        const [specificObjects, otherObjects] = array.reduce((acc, obj) => {
          if (specificIdsSet.has(obj._id)) {
            acc[0].push(obj);
          } else {
            acc[1].push(obj);
          }
          return acc;
        }, [[], []]);

        // Sort the objects with specific IDs based on their order in the specificIds array
        specificObjects.sort((a, b) => specificIds.indexOf(a._id) - specificIds.indexOf(b._id));

        // Concatenate the sorted specific objects with the other objects
        return specificObjects.concat(otherObjects);
      }
      result = sortBySpecificIdsFirst(initialGameList, specificIds.toReversed());
    }

    setGameList(result);
  };

  return (
    <div id='top'>
      <BackToTopButton />
      <div className='px-0 md:px-8'>
        <div className='bg-white px-3 py-3 flex items-center justify-between md:rounded-b-lg'>
          <h1 className='capitalize font-medium text-base opacity-70'>
            {getGameName(type)}
          </h1>
        </div>
      </div>

      <div className='px-2 md:px-8 mb-10 relative'>
        <div className='mt-2'>
          <Banner type={type} />
        </div>

        <div className='flex items-center justify-end mt-2'>
          <div className='flex items-center mr-2'>
            <label className='font-medium text-sm text-gray-500 hidden md:block'>
              Sort by
            </label>
            <div className='custom-select ml-2'>
              <select
                className='rounded-full shadow-sm py-1.5 px-4 capitalize'
                style={{ minWidth: 150 }}
                value={selectedValue}
                onChange={(event) => {
                  setSelectedValue(event.target.value);
                  handleSearch(searchText, event.target.value);
                }}
              >
                <option value=''>Filter</option>
                <option value='popularity'>Popularity</option>
                <option value='a-z'>Name A - Z</option>
                <option value='z-a'>Name Z - A</option>
                <option value='recent'>Recently Played</option>
              </select>
              <div className='chevron-icon' style={{ width: 10 }}><Image src={ChevronDown} alt='' placeholder='empty' /></div>
            </div>
          </div>
          <Search
            className='rounded-full py-0.5 search-input shadow-sm'
            placeholder='Search'
            style={{
              width: 200,
            }}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              handleSearch(e.target.value, selectedValue);
            }}
          />
        </div>

        <Provider
          data={data}
          gameBrands={gameBrands}
          gameList={gameList}
          selectedVendor={selectedVendor}
          setSelectedVendor={setSelectedVendor}
          setSearchText={setSearchText}
        />

        <div ref={elementRef} style={{ minHeight: '5vh' }}>
          <Gameh5
            games={gameList}
            limit={limit}
            selectedVendor={selectedVendor}
            loading={loading}
            setSearchText={setSearchText}
          />
          <br />
          <br />
          <br />
          <br />
          {/* <div id='loader-bottom-wrapper' className='text-center'>
            <button className='rounded-full button-secondary-transparent px-8 py-3' onClick={() => handleLimit()}>
              <p className='font-medium text-sm'>Load more</p>
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default App;
