/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Image from 'next/image';
import { getGameCred } from '../../api/getGameCred';
import ModalSwitchGame from '../dialog/switchGame';
import { SettingActions } from '../reducers/settingReducer';
import { getGameType } from '../constant/getGameType';
import Carousel from '../dashboard/Carousel';

const BRAND_NAME_CONVERT = { m8: 'MSport' };

export default function App({
  selectedVendor,
  setSelectedVendor,
  gameBrands,
  setSearchText,
}) {
  const router = useRouter();
  const dispatch = useDispatch();

  const { category } = router.query;

  const [showModalSwitch, setShowModalSwitch] = useState(false);
  const [activeGame, setActiveGame] = useState(null);

  const type = category && getGameType(category);

  useEffect(() => {
    async function fetchData() {
      // if type is live, redirect from here
      if (activeGame.gameCredId) {
        const gameProfileId = activeGame.gameCredId ? activeGame.gameCredId : process.env.BRANCH_ID;
        router.push(`/game_website?brandId=${activeGame?._id}&gameProfileId=${gameProfileId}&gameId=${activeGame?.games[0]?._id}`);
      } else {
        // dispatch(SettingActions.setLoginShow(true));
      }
      // dispatch(SettingActions.setLoading(false));
    }

    if (activeGame) {
      // 0:apk; 1:h5
      if (activeGame?.appType === '0') {
        setShowModalSwitch(true);
        setActiveGame(activeGame);
        dispatch(SettingActions.setLoading(false));
      } else if (activeGame?.games.length === 1 || activeGame?.games.length === 0) {
        fetchData();
      } else {
        // dispatch(SettingActions.setLoading(true));
        // router.push(`/${category}/${activeGame.name}`);
        setActiveGame(activeGame);
        setSelectedVendor(activeGame._id);
        setSearchText('');
        window.history.replaceState('', '', `/${category}/${activeGame.name}`);
        // setTimeout(() => {
        //   dispatch(SettingActions.setLoading(false));
        // }, 200);
      }
    }
  }, [activeGame?._id]);

  const gotoGame = async (i) => {
    if (type === 'apk') {
      const gameInfo = await getGameCred({
        brandId: i.brandId,
        type: i.type,
      });
      if (gameInfo?.error) {
        dispatch(
          SettingActions.setAlert([
            {
              msg: gameInfo?.error,
            },
          ]),
        );
      }
      if (gameInfo) {
        setActiveGame({
          gameCredId: gameInfo?.data?.data[0]?._id,
          ...gameInfo?.data?.data[0],
          games: [i],
          ...i,
        });
      }
    } else if (type === 'recommended' || type === 'hotGames') {
      const gameInfo = await getGameCred({
        brandId: i.brandId,
        type: i.type,
      });
      if (gameInfo?.error) {
        dispatch(
          SettingActions.setAlert([
            {
              msg: gameInfo?.error,
            },
          ]),
        );
      }
      if (gameInfo) {
        router.push(`/game_website?brandId=${selectedVendor}&gameProfileId=${gameInfo?.data?.data[0]?._id}&gameId=${k._id}`);
      }
    } else {
      const gameInfo = await getGameCred({ brandId: i?._id });

      const info = gameInfo?.data?.data[0];

      if (gameInfo?.data?.data[0]) {
        setActiveGame({
          gameCredId: info?._id,
          ...info,
          ...i,
        });
      } else {
        // if (gameInfo?.error) {
        //   if (!gameInfo?.error.includes('jwt')) {
        //     dispatch(
        //       SettingActions.setAlert([
        //         {
        //           msg: gameInfo?.error,
        //         },
        //       ]),
        //     );
        //   }
        // }
        if (info?._id) {
          setActiveGame({
            gameCredId: info?._id,
            ...info,
            ...i,
          });
        } else {
          setActiveGame({
            ...i,
          });
        }
      }
    }
    // dispatch(SettingActions.setLoading(false));
  };

  return (
    <div className='pb-6 pt-4'>
      <ModalSwitchGame
        activeGame={activeGame}
        show={showModalSwitch}
        toggleShow={setShowModalSwitch}
        setActiveGame={setActiveGame}
      />
      {/* <h3 className='capitalize font-medium text-sm mb-2'>
        Providers
      </h3> */}
      <Carousel
        id={`provider-${category}`}
        items={(
          <>
            {gameBrands?.map((x, i) => (
              <div
                className={`bg-white px-2 py-1 rounded-lg relative flex-item-provider game-provider-wrapper ${selectedVendor === x._id && 'game-provider-wrapper-active'}`}
                key={`${i}-${x.name}-${x.type}`}
                onClick={() => {
                  setSelectedVendor((v) => {
                    if (v === x._id) {
                      return '';
                    }
                    return x._id;
                  });
                  gotoGame(x);
                  setSearchText('');
                }}
              >
                <div className='flex items-center'>
                  <div className='mr-1'>
                    <Image

                      alt='Cricket betting sites in India'
                      className='relative z-10 mr-2'
                      width={37}
                      placeholder='empty'
                      height={37}
                      src={x.thumbnail}
                    />
                  </div>
                  <div className='cut-text-full'>
                    <p className='leading-5 text-xs font-medium capitalize '>{BRAND_NAME_CONVERT[x.name] || x.name}</p>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      />

    </div>
  );
}
