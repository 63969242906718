import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import Image from 'next/image';
import { getGameCred } from '../../api/getGameCred';
import { SettingActions } from '../reducers/settingReducer';
import ModalSwitchGame from '../dialog/switchGame';
import { gameDefaultImagepng } from '../../images';

export default function App(props) {
  const {
    games,
    limit,
    selectedVendor,
    loading,
    setSearchText,
  } = props;

  const dispatch = useDispatch();
  const router = useRouter();
  const [activeGame, setActiveGame] = useState(null);
  // const [isActive, setIsActive] = useState(null);
  const [showModalSwitch, setShowModalSwitch] = useState(false);
  const [userId, setUserId] = useState(Cookies.get('userId'));

  useEffect(() => {
    dispatch(SettingActions.setLoading(false));
  }, []);

  const gotoGame = async (k) => {
    if (Cookies.get('accessToken')) {
      dispatch(SettingActions.setLoading(true));
      const gameInfo = await getGameCred({
        brandId: selectedVendor || k.brandId,
        type: k.type,
      });
      if (gameInfo?.error) {
        dispatch(
          SettingActions.setAlert([
            {
              msg: gameInfo?.error,
            },
          ]),
        );
        dispatch(SettingActions.setLoading(false));
      } else if (gameInfo?.data?.data[0]?.brandInfo?.appType === '0') {
        setShowModalSwitch(true);
        setActiveGame({
          gameCredId: gameInfo?.data?.data[0]?._id,
          ...gameInfo?.data?.data[0],
          ...k,
        });
        dispatch(SettingActions.setLoading(false));
      } else if (gameInfo) {
        router.push(`/game_website?brandId=${selectedVendor || k.brandId}&gameProfileId=${gameInfo?.data?.data[0]?._id}&gameId=${k._id}`);
        dispatch(SettingActions.setLoading(false));
      }
    } else {
      dispatch(SettingActions.setLoginShow(true));
    }
  };

  // const ifHasHotGames = games.filter((e) => topGames.includes(e.name));

  return (
    <div>
      <p className='font-medium text-base mb-2 opacity-70'>All Games</p>
      <div className='grid grid-cols-4 sm:grid-cols-5 md:grid-cols-6 lg:grid-cols-7 gap-y-2 md:gap-3 gap-1'>
        {(games || [])
          .map((x, i) =>
            // if (i < limit) {
            (
              // const backgroundImage = `url(${k.thumbnail})`;
              <div
                className='cursor-pointer rounded-lg overflow-hidden game-overlay-wrap'
                key={`${i}-${x._id}`}
                onClick={() => {
                  const currentArray = JSON.parse(localStorage.getItem(`recentlyPlayedGames${userId}`)) || [];

                  const existingIndex = currentArray.findIndex((item) => item._id === x._id);

                  let updatedArray;

                  if (existingIndex !== -1) {
                    updatedArray = [
                      ...currentArray.slice(0, existingIndex),
                      ...currentArray.slice(existingIndex + 1),
                      x,
                    ];
                  } else {
                    updatedArray = [...currentArray, x];
                  }

                  localStorage.setItem(`recentlyPlayedGames${userId}`, JSON.stringify(updatedArray));

                  gotoGame(x);
                  setSearchText('');
                }}
              >
                <div className='relative game-overlay-img mb-1.5'>
                  <div className='game-overlay'>
                    <Button
                      htmlType='submit'
                      className='flex rounded-full text-center items-center justify-center py-3 button-primary'
                    >
                      <p className='font-medium text-xs md:text-sm leading-none'>
                        Play
                      </p>
                    </Button>
                  </div>
                  <div
                    id={`skeleton-id-${i}`}
                    className='loading skeleton-gray h-24 w-full'
                  />
                  <Image

                    className='hidden'
                    src={x.thumbnail || gameDefaultImagepng.src}
                    width={163}
                    height={163}
                    placeholder='empty'
                    layout='responsive'
                    onLoad={(e) => {
                      document.getElementById(`skeleton-id-${i}`)?.remove();
                      e.target.classList?.remove('hidden');
                    }}
                    alt='Cricket betting sites in India'
                  />
                </div>
                <div className='bg-white flex items-center w-full h-7 md:h-8 flex justify-center game-overlay-text px-2 -mt-1'>
                  <div className='leading-none mr-0.5' style={{ width: 22 }}>
                    <Image src={x.brandThumbnail} alt='' width={22} height={22} placeholder='empty' />
                  </div>
                  <p
                    className='leading-none text-xs font-semibold opacity-80 capitalize cut-text-game'
                  >
                    {/* {x.brandName || x.name} */}
                    {x.name}
                  </p>
                </div>
              </div>
            ),
            // } return null;
          )}
      </div>
      <ModalSwitchGame
        activeGame={activeGame}
        show={showModalSwitch}
        toggleShow={setShowModalSwitch}
        setActiveGame={setActiveGame}
      />
    </div>
  );
}
