export function getGameType(name) {
  if (name?.includes('sport')) {
    return 12;
  } if (name === 'live-casino') {
    return 8;
  } if (name?.includes('slot')) {
    return 0;
  } if (name === 'casino') {
    return 5;
  } if (name.includes('table')) {
    return 3;
  } if (name.includes('card')) {
    return 4;
  } if (name.indexOf('instant') >= 0) {
    return 1;
  } 
  return '';
}

export function getGameName(type) {
  if (type === 12 || type === '12') {
    return 'Sports';
  } if (type === 8 || type === '8') {
    return 'Live Casino';
  } if (type === 0 || type === '0') {
    return 'slots';
  } if (type === 5 || type === '5') {
    return 'casino';
  } if (type === 1 || type === '1' || type === 7 || type === '7') {
    return 'Instant Games';
  } if (type === 3 || type === '3') {
    return 'Table Games';
  } if (type === 4 || type === '4') {
    return 'Card Games';
  }
  return '';
}
