import { Button } from 'antd';
import Image from 'next/image';
import { RightOutlined } from '@ant-design/icons';

function CommonButton({
  onPress,
  title,
  leftIcon,
  rightIcon,
  submit,
  loading = false,
}) {
  const layoutStyle = leftIcon ? 'flex flex-1' : 'flex flex-1 justify-center';

  return (
    <Button
      loading={loading}
      style={{
        background: '#23AB5E',
      }}
      htmlType={submit && 'submit'}
      className='flex bg-buttonyellow h-12 rounded-lg items-center w-full text-center'
      onClick={() => {
        onPress && onPress();
      }}
    >
      <div className={layoutStyle}>
        {leftIcon && <Image src={leftIcon} height={20} width={20} alt='Indian Casino' placeholder='empty' />}
        <div
          className='text-sm font-normal text-white capitalize'
          style={{ marginLeft: leftIcon ? 15 : 0 }}
        >
          {title}
        </div>
      </div>
      {rightIcon && <RightOutlined className='mr-2' />}
    </Button>
  );
}

export default CommonButton;
